// Colors
$white: #fff;

$text: #74708d;
$text-lighten: #c0bdd0;
$text-darken: #615d7c;
$text-darken-more: #514d6a;

$gray: #d2d9e5;
$gray-lighten: #eef0f4;
$gray-lighten-more: #f2f4f8;
$gray-lighten-more-more: #f9fafc;
$gray-darken: #b8beca;
$gray-border: #e4e9f0;

$black: #0e0b20;
$black-lighten: #222034;
$black-lighten-more: #393749;

$blue: #08f;
$blue-darken: #0072d6;
$orange: #f2a654;
$yellow: yellow;

$menuBg: #001529;
$menuBg-darken: #000c17;

// Accent colors
$default: #acb7bf;
$primary: #77215e;
$secondary: #6a7a84;
$success: #46be8a;
$info: #0887c9;
$warning: #f39834;
$danger: #fb434a;

$green: #659714;
$slime-green: #97d700;

// Font Family
$base__font-family: 'RobotoCondensedRegular', sans-serif !important;

// Font Size
$base__font-size: 13 !default;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;

$xl-min-width: 1200px;
$xl-max-width: 1199px;

$md-min-width: 992px;
$md-max-width: 991px;

$sm-min-width: 768px;
$sm-max-width: 767px;

$xs-min-width: 576px;
$xs-max-width: 575px;

@mixin flex($alignItem: center, $justifyContent: center, $flex-wrap: nowrap) {
  display: flex;
  align-items: $alignItem;
  justify-content: $justifyContent;
  flex-wrap: $flex-wrap;
}

@mixin xsScreen {
  @media only screen and (max-width: $xs-min-width) {
    @content;
  }
}

@mixin smScreen {
  @media only screen and (width > $xs-min-width) and (max-width: $sm-min-width) {
    @content;
  }
}

@mixin mdScreen {
  @media only screen and (width > $sm-min-width) and (max-width: $md-min-width) {
    @content;
  }
}

@mixin xlScreen {
  @media only screen and (width > $md-min-width) and (max-width: $xl-min-width) {
    @content;
  }
}
@mixin xxlScreen {
  @media only screen and (width > $xl-min-width) {
    @content;
  }
}
@mixin xxxlScreen {
  @media only screen and (width > $xxl-min-width) {
    @content;
  }
}

@mixin CreateBorder($color: #c8c8c8, $width: 1px) {
  border-color: $color;
  border-style: solid;
  border-width: $width;
}
