@font-face {
  font-family: 'Ink Free';
  src: url('fonts/InkFree.eot');
  src: url('fonts/InkFree.eot?#iefix') format('embedded-opentype'), url('fonts/InkFree.woff2') format('woff2'),
    url('fonts/InkFree.woff') format('woff'), url('fonts/InkFree.ttf') format('truetype'),
    url('fonts/InkFree.svg#InkFree') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AcuminProRegular';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProRegular'), url('fonts/acumin/Acumin-RPro.woff') format('woff');
}

@font-face {
  font-family: 'AcuminProItalic';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProItalic'), url('fonts/acumin/Acumin-ItPro.woff') format('woff');
}

@font-face {
  font-family: 'AcuminProBold';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProBold'), url('fonts/acumin/Acumin-BdPro.woff') format('woff');
}

@font-face {
  font-family: 'AcuminProBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProBoldItalic'), url('fonts/acumin/Acumin-BdItPro.woff') format('woff');
}

@font-face {
  font-family: 'AcuminProCondensedBold';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProCondensedBold'), url('fonts/acumin/acuminprocond-bold.woff') format('woff');
}

@font-face {
  font-family: 'AcuminProCondensedBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProCondensedBoldItalic'), url('fonts/acumin/acuminprocond-bolditalic.woff') format('woff');
}

@font-face {
  font-family: 'AcuminProCondensedLight';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProCondensedLight'), url('fonts/acumin/acuminprocond-light.woff') format('woff');
}

@font-face {
  font-family: 'AcuminProCondensedLightItalic';
  font-style: normal;
  font-weight: normal;
  src: local('AcuminProCondensedLightItalic'), url('fonts/acumin/acuminprocond-lightitalic.woff') format('woff');
}
/* -------------- */


@font-face {
  font-family: 'RobotoCondensedRegular';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoCondensedRegular'), url('fonts/roboto/RobotoCondensed-Regular.woff') format('woff');
}
@font-face {
  font-family: 'RobotoCondensedBold';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoCondensedBold'), url('fonts/roboto/RobotoCondensed-Bold.woff') format('woff');
}
@font-face {
  font-family: 'RobotoCondensedBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoCondensedBoldItalic'), url('fonts/roboto/RobotoCondensed-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'RobotoCondensedItalic';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoCondensedItalic'), url('fonts/roboto/RobotoCondensed-Italic.woff') format('woff');
}
@font-face {
  font-family: 'RobotoCondensedLight';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoCondensedLight'), url('fonts/roboto/RobotoCondensed-Light.woff') format('woff');
}
@font-face {
  font-family: 'RobotoCondensedLightItalic';
  font-style: normal;
  font-weight: normal;
  src: local('RobotoCondensedLightItalic'), url('fonts/roboto/RobotoCondensed-LightItalic.woff') format('woff');
}